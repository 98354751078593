import './vendor/slick.min';
import './vendor/jquery.fancybox.min';

import CaseSlider from './blocks/case-slider/view.js';
import Faq from './blocks/faq/view.js';
import Slideshow from './blocks/slideshow/view.js'; 
import Tabs from './blocks/tabs/view.js';
import Testimonials from './blocks/testimonials/view.js';

docReady( function () {

    /*
    AOS.init( {
        once: true
    } )
    */

    let caseSlider = new CaseSlider();
    caseSlider.init();

    let faq = new Faq();
    faq.init();

    let slideshow = new Slideshow();
    slideshow.init();

    let tabs = new Tabs();
    tabs.init();

    let testimonials = new Testimonials();
    testimonials.init();
} )


function docReady ( fn ) {
    if ( document.readyState === "complete" || document.readyState === "interactive" ) {
        setTimeout( fn, 1 );
    } else {
        document.addEventListener( "DOMContentLoaded", fn );
    }
}