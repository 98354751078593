export default class CaseSlider{
    init(){
        jQuery( '.case .case-slider' ).slick( {
            slidesToShow: 3.5,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            responsive: [
                {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                },
              ]
        })
    }
}